import { TextField, Button, Box, Autocomplete } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useEffect, useState } from 'react'
import { useAuth } from '../contexts/AuthContext'
import kva from '../resources/kva-2022.json'
import { useMutation } from '@tanstack/react-query'
import { DayPicker } from 'react-day-picker'
import 'react-day-picker/dist/style.css'
import '../styles/date-picker.css'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { sv } from 'date-fns/locale'
import { KvaIndex } from '../hooks/KvaIndex'

const index = KvaIndex()

export default function AddOperationForm(props) {
  const [date, setDate] = useState(new Date())
  const [operator, setOperator] = useState(true)
  const { user } = useAuth()
  //const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { mutateAsync, status } = useMutation(async (data) => {
    const token = await user.getIdToken()
    const response = await axios.post('/api/operations', data, {
      headers: {
        authorization: `Bearer ${token}`
      }
    })
    return response.data
  }, {
    onSuccess: () => {
      //return queryClient.invalidateQueries()
    }
  })

  async function handleSave() {
    await mutateAsync({
      kvaCode: selectedOperation.kvaCode,
      text: selectedOperation.label,
      operator: operator,
      year: date.getFullYear(),
      month: date.getMonth(),
      date: date.getDate()
    })
    navigate(-1)
  }

  //#region Autocomplete
  const [selectedOperation, setSelectedOperation] = useState(null)
  const [inputValue, setInputValue] = useState('')
  const [options, setOptions] = useState([])
  

  useEffect(() => {
    if (index) {
      const result = index.search(inputValue, {
        limit: 20
      })
      let kvaCodes = []
      result.forEach((fieldItem) => {
        fieldItem.result.forEach((kvaCode) => {
          const foundOperationItem = kva.find((operationItem) => {
            return operationItem.kvaCode === kvaCode
          })
          kvaCodes.push(foundOperationItem)
        })
      })
      setOptions(kvaCodes)
    }
  }, [inputValue])

  //#endregion
  return (
    <Box sx={{display: 'flex', flexDirection: 'column'}}>
      <Autocomplete
        value={selectedOperation}
        onChange={(_, obj) => setSelectedOperation(obj)}
        onInputChange={(e) => setInputValue(e.target.value)}
        options={options}
        getOptionLabel={(option) => {return option.kvaCode + ' ' + option.label}}
        renderInput={(params) => <TextField {...params} variant='standard' label="Sök operation" autoComplete='off' autoFocus />}
        filterOptions={(x) => x}
        sx={{mb: 1}}
      />
      <Box sx={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
        <Box sx={{display: 'flex', flexGrow: 1, justifyContent: 'center'}}>
          <DayPicker
            mode={'single'}
            selected={date}
            onSelect={setDate}
            locale={sv}
            toDate={new Date()}
            styles={{
              caption_label: {color: '#6a8d73'},
            }}
          />
        </Box>
        <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', flexGrow: 1}} >
          <Box display={'flex'} justifyContent={'center'} gap={1} mb={2}>
            <Button onClick={() => setOperator(true)} color={'primary'} variant={operator ? 'outlined': 'text'}>Op</Button>
            <Button onClick={() => setOperator(false)} color={'secondary'} variant={!operator ? 'outlined': 'text'}>Ass</Button>
          </Box>
          <LoadingButton loading={status === 'loading'} onClick={handleSave} disabled={selectedOperation == null} variant='contained'>
            { selectedOperation ? 'Lägg till ' + selectedOperation.kvaCode : 'Välj operation' }
          </LoadingButton>
        </Box>
      </Box>
      
    </Box>
  )
}
import kva2022 from './kva-2022.json'
import kva2017 from './KVA2017v1.json'

export function getAllKvaCodes() {
  return kva2022
}

export function findOperationLabel(searchString) {
    return new Promise((resolve) => {
      const result = kva2022.find((a) => searchString.toUpperCase() === a.kvaCode)
      resolve(result.label)
    })
}

export function findOperationLabelSync(searchString) {
  const result = kva2022.find((a) => searchString.toUpperCase() === a.kvaCode)
  if (result) return result.label
  const result2017 = kva2017.find((a) => searchString.toUpperCase() === a.icd)
  if (result2017) return result2017.name
  else return 'Gammal KVA-kod'
} 
import { CircularProgress, Box } from "@mui/material"
import { useEffect, useState } from "react"
import { Bar } from 'react-chartjs-2'
import { Chart, CategoryScale, LinearScale, BarElement } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { useOperationCountsByMonth } from "../hooks/Hooks";

Chart.register(CategoryScale, LinearScale, BarElement, ChartDataLabels)

const monthLabel = ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec']

export default function HistoryChart(props) {
  const operationCountsByMonthQuery = useOperationCountsByMonth(props.kvaCode)
  const [operationCountsByMonth, setOperationCountsByMonth] = useState([])

  useEffect(() => {
    if (operationCountsByMonthQuery.data) {
      const chartData = operationCountsByMonthQuery.data.map((a) => {
        return {
          x: `${monthLabel[a.month]} ${a.year}`,
          y: a.count
        }
      })
      setOperationCountsByMonth(chartData)
    }
  }, [operationCountsByMonthQuery.data])

  if (operationCountsByMonthQuery.isLoading) {
    return  (
      <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
        <CircularProgress color='primary' />
      </Box>
    )
  }
  return (
    <Bar
        options={{
          maintainAspectRatio: false,
          responsive: true,
          animation: false,
          layout: {
            padding: {
              top: 20
            }
          },
          plugins: {
            datalabels: {
              enabled: true,
              labels: {
                title: {
                  display: false
                },
                value: {
                  formatter: (value) => {
                    return value.y
                  },
                  color: 'black',
                  anchor: 'end',
                  align: 'bottom',
                  clamp: true,
                  offset: -18,
                  font: {
                    weight: 'bold',
                    size: 12
                  }
                }
              }
            }
          },
          scales: {
            x: {
              display: true,
              grid: {
                display: false
              }
            },
            y: {
                display: false,
              }
          },
          elements: {
            bar: {
              borderRadius: 5,
              backgroundColor: '#6a8d73'
            }
          }
        }}
        data={{
          datasets: [
            {
              data: operationCountsByMonth,
              minBarLength: 2
            }
          ],
        }}
      />
  )
}
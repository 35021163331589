import { Box } from '@mui/material'
import { Route, Routes, Navigate } from 'react-router-dom'
import LoginForm from './LoginForm'
import ResetPasswordForm from './ResetPasswordForm'
import CreateAccountForm from './CreateAccountForm'

export default function LoginContainer() {

  return (
    <Box bgcolor={'white'}>
      <Routes>
        <Route path='/login' element={<LoginForm />} />
        <Route path='/forgot' element={<ResetPasswordForm />} />
        <Route path='/register' element={<CreateAccountForm />} />
        <Route path='*' element={ <Navigate replace to='/login'/>} />
      </Routes>
    </Box>
  )
}

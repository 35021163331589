import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth'


const appConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID
}

const app = initializeApp(appConfig);
const auth = getAuth(app)

export { auth }
import { Box, Typography, Button, CircularProgress } from "@mui/material";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from "react";
import {auth} from '../firebase'
import { applyActionCode } from 'firebase/auth'
import {useAuth} from '../contexts/AuthContext'


export default function VerifyLandingPage() {
  const navigate = useNavigate()
  const { updateCurrentUser } = useAuth()

  const [searchParams] = useSearchParams()
  const [isLoading, setIsLoading] = useState()
  const [emailVerificationComplete, setEmailVerificationComplete] = useState(false)
  const [emailIsVerified, setEmailIsVerified] = useState(false)
  const [error, setError] = useState()
  
  useEffect(() => {
    const mode = searchParams.get('mode')
    const actionCode = searchParams.get('oobCode')

    async function handleVerifyEmail() {
      setIsLoading(true)
      try {
        await applyActionCode(auth, actionCode)
        setEmailIsVerified(true)
      } catch (error) {
        console.log(error)
        setError(error)
      } finally {
        setIsLoading(false)
        setEmailVerificationComplete(true)
      }
    }

    if (mode === 'verifyEmail' && emailVerificationComplete === false) {
      handleVerifyEmail().catch((error) => {
        console.log(error)
      })
    }
  }, [searchParams, emailVerificationComplete])

  async function handleContinueAction() {
    //Update user from auth server refelect emailVerified: true
    await updateCurrentUser()
    navigate('/operations')
  }

  if (isLoading) {
    return (
      <Box sx={{display:'flex'}}>
        <Box sx={{margin:'auto', mt:7, display:'flex', flexDirection:'column', alignItems:'center', border: 1, borderColor: 'lightgray', p:5, borderRadius:2}}>
          <Typography variant='h6' sx={{mb:2}}>Verifierar din e-post</Typography>
          <CircularProgress />
        </Box>
      </Box>
    )
  } else if (emailIsVerified) {
    return (
      <Box sx={{display:'flex'}}>
        <Box sx={{margin:'auto', mt:7, display:'flex', flexDirection:'column', alignItems:'center', border: 1, borderColor: 'lightgray', p:5, borderRadius:2}}>
          <Typography variant='h6' sx={{mb:2}}>Din e-post har verifierats</Typography>
          <Button variant='outlined' onClick={handleContinueAction}>Gå vidare till OpLog</Button>
        </Box>
      </Box>
    )
  } else if (error) {
    return (
      <div>{error.code}</div>
    )
  } else {
    return (
      'HELLO'
    )
  }
}
import { Chip, IconButton, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from "@mui/material/CircularProgress";
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useAuth } from "../contexts/AuthContext";
import axios from "axios";

function formatDate(aDate) {
  if (aDate == null) return 'Inget datum'
  const date = new Date(aDate)
  return date.toISOString().slice(0,10)
}

export default function OperationListItem(props) {
  const queryClient = useQueryClient()
  const { user } = useAuth()
  const { mutateAsync, status } = useMutation(async () => {
    const token = await user.getIdToken()
    const response = await axios('/api/operations/' + props.operation._id, {
      method: 'delete',
      headers: {
        authorization: `Bearer ${token}`
      }
    })
    return response.data
  }, {
    onSuccess: () => {
      return queryClient.invalidateQueries()
    }
  })
  function handleDelete() {
    mutateAsync()
  }
  const operation = props.operation
  return (
    <ListItem divider={props.divider} secondaryAction={
      status !== 'loading' ?
      <IconButton loading='tr' onClick={handleDelete}>
        <CloseIcon />
      </IconButton>
      : <CircularProgress color="delete"/>
    }>
      <ListItemAvatar>
        <Chip color={operation.operator ? 'primary' : 'secondary'} label={operation.operator ? 'Op' : 'Ass'} size={'small'} variant={'outlined'} />
      </ListItemAvatar>
      <ListItemText sx={{pr: 5}} primaryTypographyProps={{noWrap: true}} primary={operation.kvaCode + ' ' + operation.text} secondary={formatDate(operation.date)} />
    </ListItem>
  )
}

import { List, ListItemButton, ListItemText, ListItemSecondaryAction, Chip } from '@mui/material'
import OperationGroupListItem from './OperationGroupListItem'

export default function OperationGroupList(props) {
   
  function hadleAllOperationsClick() {
    props.setSelectedKvaCode()
  }
  function sumOperations() {
    if (props.operationGroups == null) return 0
    const counts = props.operationGroups.map((operationGroup) => operationGroup.total)
    const sum = counts.reduce((a, b) => a + b, 0)
    return sum
  }
  return (
    <List sx={{p: 0}} dense>
      <ListItemButton selected={props.selectedKvaCode == null} onClick={hadleAllOperationsClick} divider={props.operationGroups && props.operationGroups.length > 0} sx={{pt: 2, pb: 2}}>
        <ListItemText primaryTypographyProps={{sx: {fontWeight: 400, fontSize: '18px'}}}>
          Alla operationer
        </ListItemText>
        <ListItemSecondaryAction>
          <Chip label={sumOperations()} />
        </ListItemSecondaryAction>
      </ListItemButton>
      {props.operationGroups && props.operationGroups.map((operationGroup, index, arr) => {
        return (
          <OperationGroupListItem selectedKvaCode={props.selectedKvaCode} setSelectedKvaCode={props.setSelectedKvaCode} divider={arr.length !== index + 1} key={operationGroup.kvaCode} operationGroup={operationGroup} />
        )
      })}
  </List>
  )
}

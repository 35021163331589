import React from 'react';
import { createRoot } from 'react-dom/client'
import {BrowserRouter} from 'react-router-dom'
import './styles/index.css';
import App from './components/App';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const container = document.getElementById('app')
const root = createRoot(container)

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

import { Box, Typography, CircularProgress, TextField } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from "react";
import {auth} from '../firebase'
import { verifyPasswordResetCode, confirmPasswordReset, signInWithEmailAndPassword } from 'firebase/auth'
import { LoadingButton } from "@mui/lab";


export default function ResetPasswordLandingPage(props) {
  const navigate = useNavigate()
  const [isVerifyingCode, setIsVerifyingCode] = useState(false)
  const [isUpdatingPassword, setIsUpdatingPassword] = useState(false)
  const [email, setEmail] = useState()
  const [password, setPassword] = useState('')

  useEffect(() => {
    async function handleOobCode() {
      setIsVerifyingCode(true)
      try {
        const email = await verifyPasswordResetCode(auth, props.oobCode)
        setEmail(email)
      } catch (error) {
        console.log(error)
      } finally {
        setIsVerifyingCode(false)
      }
    }
    handleOobCode()
  }, [props.oobCode])

  async function handlePasswordSubmit() {
    if (email == null || password == null) return
    setIsUpdatingPassword(true)
    try {
      await confirmPasswordReset(auth, props.oobCode, password)
      await signInWithEmailAndPassword(auth, email, password)
      navigate('/operations')
    } catch (error) {
      console.log(error)
    } finally {
      setIsUpdatingPassword(false)
    }
  } 

  if (isVerifyingCode) return (
    <Box sx={{display:'flex'}}>
      <Box sx={{margin:'auto', mt:7, display:'flex', flexDirection:'column', alignItems:'center', border: 1, borderColor: 'lightgray', p:5, borderRadius:2}}>
        <Typography variant='h6' sx={{mb:2}}>Verifierar din e-post</Typography>
        <CircularProgress />
      </Box>
    </Box>
  )
  else return (
    <Box sx={{display:'flex'}}>
      <Box sx={{margin:'auto', mt:7, display:'flex', flexDirection:'column', width: '300px', border: 1, borderColor: 'lightgray', p:2, borderRadius:2}}>
        <Typography variant='h6' sx={{mb:2}}>Äterställ lösenord</Typography>
        <TextField disabled value={email} label='E-post' sx={{mb: 2}} />
        <TextField value={password} onChange={(e) => setPassword(e.target.value)} label='Nytt lösenord' type='password' sx={{mb: 2}} />
        <LoadingButton variant='contained' onClick={handlePasswordSubmit} disabled={email == null} loading={isUpdatingPassword}>Updatera lösenord</LoadingButton>
      </Box>
    </Box>
  )
}
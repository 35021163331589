import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import VerifyLandingPage from './VerifyLandingPage'
import ResetPasswordLandingPage from './ResetPasswordLandingPage'

export default function AuthAdminLandingPage() {
  const [searchParams] = useSearchParams()
  const [mode, setMode] = useState()
  const [oobCode, setOobCode] = useState()

  useEffect(() => {
    const mode = searchParams.get('mode')
    const oobCode = searchParams.get('oobCode')
    setMode(mode)
    setOobCode(oobCode)
  }, [searchParams])

  if (mode === 'verifyEmail') return <VerifyLandingPage oobCode={oobCode} />
  if (mode === 'resetPassword') return <ResetPasswordLandingPage oobCode={oobCode} />
  else return ( <div>HEllo</div> )
}
import { useState } from 'react'
import { Button, Box, TextField, Typography, Link } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { useAuth } from '../contexts/AuthContext'

export default function LoginForm() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isLoggingIn, setIsLoggingIn] = useState(false)
  const [errorText, setErrorText] = useState()
  const {login} = useAuth()
  const navigate = useNavigate()
  async function handleSubmit(event) {
    event.preventDefault()
    setErrorText()
    setIsLoggingIn(true)
    try {
      await login(email, password)
      navigate('/operations', {replace: true})
    } catch (error) {
      if (error.code === 'auth/user-not-found' || error.code === 'auth/wrong-password') setErrorText('Fel e-post eller lösenord')
      else setErrorText('Ett fel uppstod, försök igen')
      setIsLoggingIn(false)
    }
  }

  return (
    <Box>
      <Box component='form' display={'flex'} flexDirection={'column'} onSubmit={handleSubmit} >
        <Typography variant='h6' sx={{mb: 2}}>Logga in</Typography>
        <TextField value={email} onChange={(e) => setEmail(e.target.value)} type='email' autoComplete='username' fullWidth label='E-post' sx={{mb: 2}}/>
        <TextField value={password} onChange={(e) => setPassword(e.target.value)} type='password' autoComplete='current-password' fullWidth label='Lösenord' sx={{mb: 2}}/>
        <LoadingButton type='submit' variant='contained' fullWidth sx={{mb: 1}} loading={isLoggingIn}>Logga in</LoadingButton>
        <Link component={RouterLink} to='/hello/forgot' variant='body1' alignSelf={'flex-end'}>Glömt lösenord</Link>
        <Typography color='red'>{errorText}</Typography>
      </Box>
      <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
        <Box bgcolor={'gray'} height={'1px'} width={1/2} my={2} />
        <Button to='/hello/register' component={RouterLink} variant='outlined' fullWidth>Skapa konto</Button>
      </Box>
    </Box>
  )
}
import { Box, Typography, Link } from '@mui/material'
import {Link as RouterLink} from 'react-router-dom'

export default function UserPolicy() {
    return (
        <Box sx={{m: 2}}>
            <Link to='/hello/register' component={RouterLink} variant='body1'> Tillbaka </Link>

            <Typography variant='h4'>Användarvilkor för oplog.se</Typography>
            <Typography variant='h6'>A. Syfte med tjänsten oplog.se</Typography>
            <Typography>oplog.se är en tjänst som syftar till att hjälpa dig som opererande läkare hålla koll på vilka och hur många ingrepp du har utfört.</Typography>
            
            <Typography variant='h6'>B. Användande av tjänsten</Typography>
            <Typography>1. Du som användare ansvarar för den information som du registrerar.</Typography>
            <Typography>2. oplog.se ansvarar inte för eventuell dataförlust och dess följder det kan få.</Typography>
            <Typography>3. oplog.se äger rätten att avsluta ett konto för användare som anses utnyttja tjänsten på ett olämpligt eller olovligt sätt.</Typography>
            <Typography>4. Du har som användare rätt att när som helst avlsuta ditt konto på oplog.se och all information kopplat till kontot kommer då raderas</Typography>
            
            <Typography variant='h6'>C. Personuppgifter</Typography>
            <Typography>1. Vi samlar in information om dig för att möjliggöra inloggning till tjänsten och göra den så säker som möjligt. Uppgifter som ingår är: e-post, IP-nummer, geografisk plats, webläsartyp och operativsystem</Typography>
            <Typography>2. Den insamlade informationen kan också användas för att kommunicera information om tjänsten till dig som användare.</Typography>
            <Typography>3. Vi kan komma att lämna ut den personliga information i den mån vi är skyldiga enligt lag.</Typography>
            <Typography>4. Den personliga informationen delas annars inte med tredje part</Typography>

            <Typography variant='h6'>D. Kakor</Typography>
            <Typography>oplog.se använder kakor och genom att använda tjänsten godkänner du det. Kakorna krävs för att kunna logga in och nyttja tjänsten </Typography>

            <Typography variant='h6'>E. Ändringar</Typography>
            <Typography>Vi kan komma att uppdatera användarvilkoren genom att publicera en ny version på vår hemsida. Du bör kontrollera denna sida emellanåt för att se till att du förstår eventuella ändringar.</Typography>

        </Box>
    ) 
}
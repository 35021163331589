import {Box, TextField, Typography, Link} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import axios from 'axios'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

export default function ResetPasswordForm() {
  const [email, setEmail] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [errorText, setErrorText] = useState()
  const [sendEmailSuccess, setSendEmailSuccess] = useState(false)
  const navigate = useNavigate()

  async function handleSubmit(event) {
    event.preventDefault()
    setErrorText()
    setIsLoading(true)
    try {
      await axios.post('/api/account/reset-password', { email })
      setSendEmailSuccess(true)
    } catch (error) {
      setErrorText('Ett fel uppstod, försök igen')
    } finally {
      setIsLoading(false)
    }
    
  }
  if (!sendEmailSuccess) return (
    <Box component='form'>
      <Typography variant='h6' sx={{mb: 2}}>Återställ lösenord</Typography>
      <TextField label='E-mail' value={email} onChange={(e) => setEmail(e.target.value)} autoComplete='off' fullWidth />
      <LoadingButton variant='contained' loading={isLoading} onClick={handleSubmit} fullWidth sx={{mt: 2, mb: 2}}>Skicka återställningslänk</LoadingButton>
      <Typography color='red'>{errorText}</Typography>
      <Link onClick={() => {navigate('/')}} variant='body1'>Tillbaka</Link>
    </Box>
  )
  else return (
    <Box sx={{display:'flex', flexDirection:'column', alignItems:'center'}}>
      <CheckCircleIcon fontSize='large' color='primary' sx={{mb:3}} />
      <Typography>Ett mail har skickats till dig för att återställa lösenordet</Typography>
    </Box>
  )
}
import { Box, Typography } from '@mui/material'
import LoginContainer from './LoginContainer'
import { useAuth } from '../contexts/AuthContext'
import { Navigate } from 'react-router-dom'
import Grid from '@mui/material/Unstable_Grid2'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

export default function LoggedOut() {
  const {user} = useAuth()
  if (user) return <Navigate replace to='/operations' /> // Redirect to logged in page if a logged in user ends up here for some reason
  return (
    <Grid container spacing={1} sx={{bgcolor: 'primary.main', height: '100%'}}>
      <Grid xs={12} md={6} sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
        <Typography color='white' variant='h1'>OpLog</Typography>
        <Typography color='white' variant='h5'>Din operationslogg i molnet</Typography>
        <Box sx={{bgcolor: 'white', borderRadius: 2, alignSelf: 'stretch', m: 2, p: 1, display: {'xs': 'none', 'md': 'block'}}}>
          <InfoOutlinedIcon />
          <Typography>2023-01-17</Typography>
          <Typography>Oplog är nu bättre mobilanpassad och funkar på mindre skärmar. Testa! 🚀</Typography>
        </Box>
      </Grid>
      <Grid xs={12} md={6} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <Box sx={{maxWidth: {'md': 400, 'xs': 300}, flexGrow: 1, borderRadius: 2, boxShadow: 3, p: 3, bgcolor: 'white'}}>
            <LoginContainer />
        </Box>
      </Grid>
    </Grid>
  )
}
import OperationListItem from './OperationListItem'
import { List, Box, Typography } from '@mui/material'


export default function OperationsList(props) {
  
  if (props.operations.length === 0) return (
    <Box p={5} width={'100%'} display={'flex'} justifyContent={'center'}>
      <Typography variant={'h4'}>
        Inga operationer :/
      </Typography>
    </Box>
  )
  return (
    <>
      <Box border={1} borderRadius={2} borderColor={'grey.500'}>
        <List dense sx={{p: 0, width: '100%'}}>
          {props.operations && props.operations.map((operation, index, arr) => {
            return (
              <OperationListItem divider={arr.length !== index + 1} key={operation._id} operation={operation} />
            )
          })}
        </List>
      </Box>
    </>
  )
}

import kva from '../resources/kva-2022.json'
import * as FlexSearch from 'flexsearch'

let index

function createIndex() {
  console.log('Indexing...')
    const anIndex = new FlexSearch.Document({
      worker: false,
      document: {
        id: 'kvaCode',
        index: [{
          field: 'kvaCode',
          tokenize: 'forward'
        }, {
          field: 'label',
          tokenize: 'full'
        }]
      }
    })
    for (const item of kva) {
      anIndex.add(item)
    }
    console.log('Indexing done!')
    index = anIndex
}

export function KvaIndex() {
  if (index == null) {
    createIndex()
  }
  return index
}
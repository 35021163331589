import { useContext, createContext, useState, useEffect } from 'react'
import {auth} from '../firebase'
import {signInWithEmailAndPassword, createUserWithEmailAndPassword, signOut, applyActionCode} from 'firebase/auth'

const AuthContext = createContext()

export function useAuth() {
  return useContext(AuthContext)
}

export function AuthProvider({children}) {
  const [user, setUser] = useState()
  const [loading, setLoading] = useState(true)

  function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password)
  }
  function logout() {
    return signOut(auth)
  }
  function signup(email, password) {
    return createUserWithEmailAndPassword(auth, email, password)
  }
  function verifyEmail(actionCode) {
    return applyActionCode(auth, actionCode)
  }
  async function updateCurrentUser() {
    if (auth.currentUser) {
      await auth.currentUser.reload()
      setUser(auth.currentUser)
    }
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setUser(user)
      setLoading(false)
    })
    return unsubscribe
  }, [])

  const value = {
    user,
    signup,
    login,
    logout,
    verifyEmail,
    updateCurrentUser
  }
  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )
}

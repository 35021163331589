import { Chip, ListItem, ListItemButton, ListItemSecondaryAction, ListItemText } from '@mui/material'
import { useEffect, useState } from 'react'
import { findOperationLabelSync } from '../resources/KvaLibrary'

export default function OperationGroupListItem(props) {
  const [operationLabel, setOperationLabel] = useState()
  
  useEffect(() => {
    const label = findOperationLabelSync(props.operationGroup.kvaCode)
    setOperationLabel(label)
  }, [props.operationGroup.kvaCode])
  
  function handleClick() {
    props.setSelectedKvaCode(props.operationGroup.kvaCode)
  }

  return (
    <ListItem disablePadding  divider={props.divider}>
      <ListItemButton selected={props.selectedKvaCode === props.operationGroup.kvaCode} onClick={handleClick}>
        <ListItemText primary={props.operationGroup.kvaCode} secondary={operationLabel} sx={{pr: 4}} />
        <ListItemSecondaryAction>
          <Chip label={props.operationGroup.total} variant={'outlined'} />
        </ListItemSecondaryAction>
      </ListItemButton>
    </ListItem>
  )
}

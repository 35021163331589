import { Box, Typography, Button, Dialog, DialogTitle, DialogContent, DialogContentText, TextField } from "@mui/material"
import {useAuth} from '../contexts/AuthContext'
import { Container } from "@mui/system"
import { useState } from "react"
import { deleteUser, reauthenticateWithCredential, EmailAuthProvider } from 'firebase/auth'
import { LoadingButton } from "@mui/lab"

export default function AccountView() {

  const [dialogIsOpen, setDialogIsOpen] = useState(false)
  const { user } = useAuth()

  function handleOpenDialog() {
    setDialogIsOpen(true)
  }
  function handleCloseDialog() {
    setDialogIsOpen(false)
  }
  

  return (
    <Container maxWidth='sm' sx={{mt: 2}}>
      <Typography variant='h3'>Konto</Typography>
      <Box sx={{mt: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'baseline', borderBottom: 1, borderColor: 'lightgrey' }}>
        <Typography variant='h6'>Inloggad som</Typography>
        <Typography>{user.email}</Typography>
      </Box>
      <Box sx={{mt: 3, display: 'flex', flexDirection: 'column', borderBottom: 1, borderColor: 'lightgrey'}}>
        <Typography variant='h6'>Ta bort konto</Typography>
        <Typography>Om du tar bort ditt konto kommer operationer och andra uppgifter kopplat till ditt konto raderas och kan inte återskapas</Typography>
        <Button onClick={handleOpenDialog} variant='outlined' color='error' sx={{mb: 2, mt: 1}}>Ta bort konto...</Button>
        <RemoveAccountDialog open={dialogIsOpen} onClose={handleCloseDialog} user={user}/>
      </Box>
    </Container>
  )
}

function RemoveAccountDialog(props) {
  const {open, onClose, user} = props
  const [isDeletingAccount, setIsDeletingAccount] = useState(false)
  const [emailInput, setEmailInput] = useState('')
  const [passwordInput, setPasswordInput] = useState('')
  const [errorText, setErrorText] = useState()
  async function handleDeleteAccount() {
    setIsDeletingAccount(true)
    setErrorText()
    try {
      const credentials = EmailAuthProvider.credential(emailInput, passwordInput)
      await reauthenticateWithCredential(user, credentials)
      await deleteUser(user)
    } catch (error) {
      if (error.code === 'auth/wrong-password') setErrorText('Fel lösenord')
    } finally {
      setIsDeletingAccount(false)
    }
  }
  function handleDialogClose() {
    setEmailInput('')
    setPasswordInput('')
    onClose()
  }
  return (
    <Dialog open={open} onClose={handleDialogClose}>
      <DialogTitle>Ta bort konto</DialogTitle>
      <DialogContent>
        <DialogContentText>Skriv in e-post och lösenord igen för att ta bort ditt konto</DialogContentText>
        <TextField value={emailInput} onChange={(e) => setEmailInput(e.target.value)} type='email' fullWidth variant='standard' label='E-post' sx={{mb: 1, mt: 1}}/>
        <TextField value={passwordInput} onChange={(e) => setPasswordInput(e.target.value)} type='password' fullWidth variant='standard' label='Lösenord'/>
        <Typography color='error' sx={{mt: 1}}>{errorText}</Typography>
        <LoadingButton disabled={emailInput !== user.email} onClick={handleDeleteAccount} fullWidth variant='contained' color="error" sx={{mt: 2}} loading={isDeletingAccount}>Ta bort konto</LoadingButton>
      </DialogContent>
    </Dialog>
  )
}
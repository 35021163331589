import { Box, CircularProgress, Container, Typography, IconButton, Button, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useState, useEffect } from 'react'
import OperationsList from './OperationsList'
import OperationGroupList from './OperationGroupList'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { kOperationItemsPerPage } from '../constants/constants'
import { useOperationCounts, useOperations, useOperationCountsByMonth } from '../hooks/Hooks'
import { useNavigate } from 'react-router-dom';
import HistoryChart from './HistoryChart';

function CountsLabel(props) {
  return (
    <>
      <Typography variant={'h4'}>{props.selectedKvaCode ? props.selectedKvaCode : 'Alla operationer'}</Typography>
      <Box sx={{display: 'flex'}}>
        <Typography sx={{mr: 1, color: 'secondary.main'}} variant='h6'>{props.counts.assistant}</Typography>
        <Typography sx={{mr: 1, color: 'primary.main'}} variant='h6'>{props.counts.operator}</Typography>
        <Typography variant='h6'>{props.counts.total}</Typography>
      </Box>
    </>
  )
}

export default function Home({operationGroupsListIsActive, onSlectedKvaCodeChange}) {
  const [selectedKvaCode, setSelectedKvaCode] = useState()
  const [counts, setCounts] = useState()
  const [page, setPage] = useState(0)
  const [totalPages, setTotalPages] = useState()
  const operationsQuery = useOperations(selectedKvaCode, page)
  const operationGroupsQuery = useOperationCounts()
  const operationCountsByMonthQuery = useOperationCountsByMonth(selectedKvaCode)
  const [countsLastYear, setCountsLastYear] = useState()
  const navigate = useNavigate()
  const [operationGroupsViewIsVisible, setOperationGroupsViewIsVisible] = useState(false)
  
  // Sizing
  const theme = useTheme()
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'))

  // Pagination logic
  useEffect(() => {
    setPage(0)
  }, [selectedKvaCode])
  function handleNext() {
    setPage(page + 1)
  }
  function handlePrevious() {
    if (page > 0) return setPage(page - 1)
    setPage(0)
  }
  // Operation groups view
  useEffect(() => {
    if (isLargeScreen) return setOperationGroupsViewIsVisible(true)
    if (operationGroupsListIsActive) return setOperationGroupsViewIsVisible(true)
    else setOperationGroupsViewIsVisible(false)
  }, [operationGroupsListIsActive, isLargeScreen])

  // On kva code change, change parent state to deactivate operation groups list
  useEffect(() => {
    onSlectedKvaCodeChange()
  }, [selectedKvaCode, onSlectedKvaCodeChange])

  // Calculate and set total pages and counts state when selected kvaCode or operationsGroups data changes
  useEffect(() => {
    if (operationGroupsQuery.data) {
      const operationGroups = operationGroupsQuery.data
      let sum
      if (selectedKvaCode) {
        const operationGroup = operationGroups.find((operationGroup) => {
          return operationGroup.kvaCode === selectedKvaCode
        })
        if (operationGroup) {
          sum = operationGroup.total
          setCounts({
            operator: operationGroup.operator,
            assistant: operationGroup.assistant,
            total: operationGroup.total
          })
        }
        else setSelectedKvaCode()
      } else {
        const counts = operationGroups.map((operationGroup) => operationGroup.total)
        sum = counts.reduce((a, b) => a + b, 0)
        const totalCounts = operationGroups.map((operationGroup) => operationGroup.total)
        const assistantCounts = operationGroups.map((operationGroup) => operationGroup.assistant)
        const opeatorCounts = operationGroups.map((operationGroup) => operationGroup.operator)
        setCounts({
          operator: opeatorCounts.reduce((a, b) => a + b, 0),
          assistant: assistantCounts.reduce((a, b) => a + b, 0),
          total: totalCounts.reduce((a, b) => a + b, 0)
        })
      }
      if (sum) setTotalPages(Math.ceil(sum / kOperationItemsPerPage))
      else setTotalPages()
    }
  }, [operationGroupsQuery.data, selectedKvaCode])

  useEffect(() => {
    console.log(operationCountsByMonthQuery.data)
    if (operationCountsByMonthQuery.data) {
      const totalCountLastYear = operationCountsByMonthQuery.data.reduce((acc, month) => {
        return acc + month.count
      }, operationCountsByMonthQuery.data[0].count)
      return setCountsLastYear(totalCountLastYear)
    }
  }, [operationCountsByMonthQuery.data])

  // RENDER
  if (!operationsQuery.isLoading && !operationGroupsQuery.isLoading && counts) {
    if (selectedKvaCode == null && counts.total === 0)
    return (
      <Box sx={{display: 'flex', height: '100%'}}>
        <Box sx={{margin: 'auto', display:'flex', flexDirection: 'column', alignItems: 'center', bgcolor: '#eeeeee', p: 5, borderRadius: 2}}>
          <Typography variant='h6' sx={{mb: 3}}>Inga operationer :/</Typography>
          <Button variant='outlined' onClick={() => navigate('/add-operation')}>Lägg till op</Button>
        </Box>
      </Box>
    )
    else return (
      <Box sx={{display: 'flex', height: '100%'}}>
        <Box sx={{display: operationGroupsViewIsVisible ? 'flex' : 'none' , flexBasis: isLargeScreen ? {'sm': '250px', 'md': '350px'} : '100%', borderRight: isLargeScreen ? 1 : 0, borderColor: 'grey.500', overflowY: 'auto', flexShrink: 0}}>
          <OperationGroupList operationGroups={operationGroupsQuery.data} selectedKvaCode={selectedKvaCode} setSelectedKvaCode={setSelectedKvaCode} />
        </Box>
        <Box sx={{flexBasis: '100%', overflowY: 'auto', p: 2}}>
          <Box sx={{display: 'flex', alignItems: 'baseline', flexWrap: 'wrap', columnGap: '5px'}}>
            <CountsLabel selectedKvaCode={selectedKvaCode} counts={counts} />
            {/* TODO: Place box for "days since last surgery" here" */}
          </Box>
          <Box>
            <Typography sx={{display: 'inline', fontWeight: 'bold'}}> {countsLastYear} </Typography>
            <Typography sx={{display: 'inline'}}>operationer senaste året</Typography>
          </Box>
          <Box sx={{mb: 2, height: 200}}>
            <HistoryChart kvaCode={selectedKvaCode} />
          </Box>
          <OperationsList operations={operationsQuery.data} />
          { totalPages > 1 &&
          <Box display={'flex'} justifyContent={'center'} width={'100%'} mt={2}>
            <IconButton onClick={handlePrevious} disabled={page === 0} variant={'outlined'} sx={{mr: 2}}>
              <ArrowBackIosNewIcon />
            </IconButton>
            <IconButton onClick={handleNext} disabled={page >= totalPages - 1} variant={'outlined'}>
              <ArrowForwardIosIcon />
          </IconButton>
          </Box> }
        </Box>
      </Box>
    )
  }
  return (
    <Container sx={{mt: 5}} fixed>
      <Box width={'100%'} display={'flex'} flexDirection={'column'} alignItems={'center'}>
        <CircularProgress color='primary' />
        <Typography mt={2} variant={'h6'}>Laddar operationer</Typography>
      </Box>
    </Container>
  )
}

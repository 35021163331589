import { Container, Box } from '@mui/material'
import React from 'react'
import AddOperationForm from './AddOperationForm'

export default function AddOperationView() {
  return (
    <Container maxWidth='sm' sx={{mt: 2}}>
      <Box p={2} border={1} borderRadius={2} borderColor={'grey.500'}>
        <AddOperationForm />
      </Box>
    </Container>
  )
}

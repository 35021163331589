import { Box, Typography, TextField, Link } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import {Link as RouterLink} from 'react-router-dom'
import { useState } from 'react'
import {useAuth} from '../contexts/AuthContext'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

function CreateAccountForm() {
  const [email, setEmail] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [password, setPassword] = useState('')
  const [errorText, setErrorText] = useState()
  const [signUpSuccess, setSignupSuccess] = useState(false)
  const {signup} = useAuth()

  async function handleFormSubmit(event) {
    event.preventDefault()
    setIsLoading(true)
    setErrorText(undefined)
    try {
      await signup(email, password)
      setSignupSuccess(true)
    } catch (error) {
      if (error.code === 'auth/email-already-in-use') setErrorText('E-postadressen används redan')
      else setErrorText('Ett fel uppstod när kontot skulle skapas')
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }
  if (!signUpSuccess) return (
    <Box component='form' onSubmit={handleFormSubmit}>
      <Typography variant='h6'>Skapa konto</Typography>
      <TextField value={email} onChange={(e) => setEmail(e.target.value)} label='E-mail' autoComplete='username' fullWidth  sx={{my: 2}}/>
      <TextField value={password} onChange={(e) => setPassword(e.target.value)} label='Lösenord' autoComplete='new-password' type='password' fullWidth />
      <Typography variant='body1' color='gray'>Genom att skapa ett konto godkänner du</Typography>
      <Link to='/hello/user-agreement' component={RouterLink} variant='body1'>användarvilkoren</Link>
      <LoadingButton type='submit' variant='contained' loading={isLoading} fullWidth sx={{mt: 2, mb: 1}}>Skapa konto</LoadingButton>
      { errorText ?
        <Typography variant='body1' color='red'>{errorText}</Typography> :
        <Typography variant='body1' color='gray'>Ett mail kommer att skickas till dig med en länk för att bekräfta kontot</Typography>
      }
      <Link to='/' component={RouterLink} variant='body1'>Tillbaka</Link>
    </Box>
  )
  else return (
    <Box sx={{display:'flex', flexDirection:'column', alignItems:'center'}}>
      <CheckCircleIcon fontSize='large' color='primary' sx={{mb:3}} />
      <Typography>Ett mail har skickats till dig för att bekräfta kontot</Typography>
    </Box>
  )
}

export default CreateAccountForm
import { useQuery } from "@tanstack/react-query"
import  { useAuth } from '../contexts/AuthContext'
import { kOperationItemsPerPage } from '../constants/constants'
import axios from 'axios'

export const useOperationCounts = () => { 
  const { user } = useAuth()
  return useQuery(['operationCounts'], async () => {
    const token = await user.getIdToken()
    const response = await axios.get('/api/counts', {
      headers: {
        authorization: `Bearer ${token}`
      }
    })
    return response.data
  })
}

export const useOperations = (selectedKvaCode, page) => {
  const { user } = useAuth()
  return useQuery(['operations', selectedKvaCode, page], async () => {
    const token = await user.getIdToken()
    const response = await axios.get(`/api/operations`, {
      headers: {
        authorization: `Bearer ${token}`
      },
      params: {
        kvaCode: selectedKvaCode,
        itemsPerPage: kOperationItemsPerPage,
        page
      }
    })
    return response.data
  }, {
    keepPreviousData: true
  })
}

export const useOperationCountsByMonth = (kvaCode) => {
  const { user } = useAuth()
  return useQuery(['operationCountsByMonth', kvaCode], async () => {
    const now = new Date()
    const token = await user.getIdToken()
    const response = await axios.get('/api/countsByMonth', {
      headers: {
        authorization: `Bearer ${token}`
      },
      params: {
        endYear: now.getFullYear(),
        endMonth: now.getMonth(),
        kvaCode
      }
    })
    return response.data
  })
}
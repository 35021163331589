import { Routes, Route, Navigate } from "react-router-dom"
import LoggedIn from "./LoggedIn"
import {AuthProvider, useAuth} from '../contexts/AuthContext'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import LoggedOut from "./LoggedOut"
import { createTheme, ThemeProvider } from "@mui/material"
import AuthAdminLandingPage from "./AuthAdminLadingPage"
import UserPolicy from "./UserPolicy"

function PrivateRoute({children}) {
  const {user} = useAuth()
  return user ? children : <Navigate to='/hello/login' />
}

const oploggTheme = createTheme({
  palette: {
    primary: {
      main: '#6a8d73'
    },
    secondary: {
      main: '#f0a868'
    },
    operator: {
      main: '#F4FDD9',
      contrastText: '#000'
    },
    assistant: {
      main: '#F9EECA',
      contrastText: '#000'
    },
    delete: {
      main: '#f44336'
    },
  }
})

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
})
function App() {
  return (
    <QueryClientProvider client={queryClient}>
      {/* <ReactQueryDevtools /> */}
      <AuthProvider>
        <ThemeProvider theme={oploggTheme}>
          <Routes>
            <Route path='/*' element={
              <PrivateRoute>
                <LoggedIn />
              </PrivateRoute>
            } />
            <Route path='/hello/*' element={<LoggedOut />} />
            <Route path='/hello/user-agreement' element={<UserPolicy />} />
            <Route path='/account/auth-admin' element={<AuthAdminLandingPage />} />
            <Route path='*' element={ <Navigate replace to='/hello/login'/>} />
          </Routes>
        </ThemeProvider>
      </AuthProvider>
    </QueryClientProvider>
  )
}

export default App
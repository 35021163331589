import { Box, Link, Toolbar } from '@mui/material'
import {useAuth} from '../contexts/AuthContext'
import {Routes, Route, useNavigate, Navigate} from 'react-router-dom'
import OperationsView from './OperationsView'
import OplogAppBar from './OplogAppBar'
import AddOperationView from './AddOperationView'
import AccountView from './AccountView'
import { useCallback, useState } from 'react'

export default function LoggedIn() {
  const {logout, user} = useAuth()
  const navigate = useNavigate()
  const [operationGroupsListIsActive, setOperationGroupsListIsActive] = useState(false)

  function toggleOperationGroupsList() {
    setOperationGroupsListIsActive(!operationGroupsListIsActive)
  }
  const deactivateOperationGroupsList = useCallback(() => {
    setOperationGroupsListIsActive(false)
  }, [])

  async function handleLogout() {
    try {
      await logout()
      navigate('/login')
    } catch (error) {
      console.log(error)
    }
  }

  if(user) {
    if (!user.emailVerified) {
      return (
        <Box>
          <Box>Unverified</Box>
          <Box>{user.email}</Box>
          <Link onClick={handleLogout}>Logga ut</Link>
        </Box>
      )
    } else {
      return (
        <>
          <OplogAppBar toggleOperationGroupsList={toggleOperationGroupsList} />
          <Box sx={{height: '100%', boxSizing: 'border-box'}}>
            <Toolbar /> {/* For spacing */}
            <Routes>
              <Route path='/operations' element={ <OperationsView  operationGroupsListIsActive={operationGroupsListIsActive} onSlectedKvaCodeChange={deactivateOperationGroupsList} /> } />
              <Route path='/add-operation' element={ <AddOperationView /> } />
              <Route path='/user' element={ <AccountView /> } />
              <Route path='*' element={ <Navigate replace to='/operations'/>} />
            </Routes>
          </Box>
        </>
      )
    }
  } else {
    return (
      <div>Loading</div>
    )
  }
}
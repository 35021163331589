import { AppBar, Box, IconButton, Toolbar, Typography, Button, useTheme, useMediaQuery } from '@mui/material'
import {useAuth} from '../contexts/AuthContext'
import LogoutIcon from '@mui/icons-material/Logout'
import { useNavigate, useLocation } from 'react-router-dom'
import MenuIcon from '@mui/icons-material/Menu'
import AddIcon from '@mui/icons-material/Add'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import { useEffect, useState } from 'react'

export default function OplogAppBar(props) {
  const {user, logout} = useAuth()
  const navigate = useNavigate()
  const [isOperationsView, setIsOperationsView] = useState(false)

  // Sizing
  const theme = useTheme()
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'))

  const location = useLocation()

  useEffect(() => {
    if (location.pathname === '/operations') setIsOperationsView(true)
    else setIsOperationsView(false)
  }, [location])

  return (
    <AppBar position='fixed'>
      <Toolbar sx={{display: 'flex', justifyContent: 'space-between'}}>
        <IconButton color='inherit' size='large' edge='start' sx={{display: () => isLargeScreen || !isOperationsView ? 'none' : null}} onClick={props.toggleOperationGroupsList} >
          <MenuIcon />
        </IconButton>
        <Box sx={{display: () => isLargeScreen || !isOperationsView ? 'flex' : 'none', alignItems: 'baseline'}} >
          <Typography onClick={() => navigate('operations')} sx={{cursor: 'pointer'}} variant='h6'>
            OpLog
          </Typography>
        </Box>
        <Box display={'flex'} alignItems={'center'}>
          <Button onClick={() => navigate('add-operation')} color='inherit' variant='outlined' sx={{display: {'xs': 'none', 'md': 'block'}, mr: 2}}>
            Lägg till op
          </Button>
          <IconButton onClick={() => navigate('add-operation')} color='inherit' sx={{display: {'xs': null, 'md': 'none'}}}>
            <AddIcon />
          </IconButton>
          <Button color='inherit' onClick={() => navigate('/user')} sx={{display: {'xs': 'none', 'md': 'block'}}}>
            {user.email}
          </Button>
          <IconButton color='inherit' onClick={() => navigate('/user')} sx={{display: {'xs': null, 'md': 'none'}}}>
            <ManageAccountsIcon />
          </IconButton>
          <IconButton onClick={logout}>
            <LogoutIcon sx={{color: 'white'}} />
          </IconButton>
        </Box>
      </Toolbar>
    </AppBar>
  )
}
